import React from 'react'
import Immagine from '../../assets/PNRRLogo.webp'
import {Row,Col} from 'react-bootstrap'
import './stile.css';
import parser from 'react-html-parser';
import PropTypes from 'prop-types';

/**
 * @param {any} titolo : (Left) Titolo del avviso
 * @param {any} testo : (Left) Testo dell'avviso
 * @param {string} htmlText : (Left) Corpo dell'avviso
 * @param {any} subImg : (Right) Corpo sotto immagine
 * @returns 
 */
function Presentazione({
    titolo,
    testo,
    htmlText,
    subImg
}){

    return(
        <Row className="BordoContainer" noGutters>
            <Col sm="8">
                <div className="containerTitolo">{parser(titolo)}</div>
                <div className="TestoPresentazione">
                    {testo}
                    {parser(htmlText)}
                </div>
            </Col>
            <Col>
                <div className="ContainerImmagine">
                <img src={Immagine} alt="PNRR pic" className="ImmaginePresentazione"/>
                </div>
                <div className='testoAfter'>
                <br/><br/>
                {subImg}
                </div>
            </Col>
        </Row>
    )

}

Presentazione.propTypes = {
    titolo : PropTypes.any,
    testo : PropTypes.any,
    htmlText : PropTypes.string,
    subImg : PropTypes.any
}

export default Presentazione;