import React from 'react'
import './stile.css'
import parser from 'react-html-parser'
import { Link } from 'gatsby'
export default function AvvisiBando(props){

    const optional = props.optional

    const stileBox = props.stileBox ? props.stileBox : {backgroundColor:"#CCE5FF"}
    

    const getBody = () =>{
        return <div>
            <div className="titoloBando">{props.titolo}</div>
            <div className="testoBando">{parser(props.testo)}</div>
        </div>
    }

    return (<div>
    <div className="bordoBando" style={stileBox}>
        {props.link ? 
        <Link to={props.link} {...optional}>
            {getBody()}
        </Link>
        :
        <div role="button" {...optional} onClick={()=>props.openModal()}>
            {getBody()}
        </div>
        }
    </div>
    <br/>
    </div>)
}