import React from 'react'
import {Row,Col} from 'react-bootstrap';
import Logo from '../../assets/LogoCbimPaint.png'
import './stile.css'

export default function JumbotronFooter(){

    return(
            <Row className="jumbo" noGutters>
                    <Col className="ContainerJumbo" md="3">
                    <div className="containerImmagineJumbo">
                        <a href="http://www.cbim.it/index.html">
                            <img src={Logo}
                            alt="Consorzio di Bioingegneria e Informatica Medica"
                            className="ImmagineJumbo"/>
                        </a>
                    </div>
                    </Col>
                    <Col className="TestoJumbo">
                            <b>Piazzale Volontari del Sangue, 2&emsp;Pavia&emsp;ITALY</b>
                            <br/>
                            Technical assistance is provided by <b>Consortium of Bioengineering and Medical Informatics (CBIM)</b> of Pavia.
                            The service is available Monday through Friday (hours 9:00 - 18:00 GMT+1) (except national holidays): 
                            <br/>
                            <a href="https://cbim.freshdesk.com/support/home" target="_blank" rel="noopener noreferrer"> <b>Help Desk CBIM</b> </a>&emsp;-&emsp;<a href="https://cbim.freshdesk.com/support/solutions" target="_blank" rel="noopener noreferrer"><b>FAQ</b></a>
                    </Col>
            </Row>
    )
} 