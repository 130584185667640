import React from 'react'

import {Helmet} from "react-helmet"
import {Row,Col} from 'react-bootstrap'

import LinkButtonTop from '../components/basicComponents/LinkButtonTop'
import JumbotronFooter from '../components/oneTimeComponents/JumbotronFooter'
import RaccoltaLinkENG from '../components/RaccoltaEN/RaccoltaLinkENG'
import NavBar from '../components/oneTimeComponents/NavigationBar'
import Presentazione from '../components/basicComponents/Presentazione'
import AvvisiBando from '../components/basicComponents/AvvisiBando'
import AvvisoGuida from '../components/basicComponents/AvvisoGuida'
import './stile.css'
import Countdown from '../components/ExtraComponents/Countdown'

export default function paginaInglese(){

    const presentazioneContent = <div>
    <br/>
    The Ministry of Health implementing the interventions identified within the "Health-environment-biodiversity-climate" program of the National Investment Plan
    Complementary to the National Recovery and Resilience Plan, in order to develop a new institutional structure, functional to the topic
    “Health, environment, biodiversity and climate”, which can redesign and strengthen the national health system –
    SSN through a holistic One Health approach, with the aim of strengthening the protection of the health of
    citizens with respect to the currently known risks and the looming challenges of an environmental-climatic nature and support
    sustainable development as well as safe economic growth, especially in relation to the transition
    green and digital transformation, finances 14 applied research projects falling into the following two
    macro areas:
    <br/><br/>
    The 14 projects eligible for funding following the two public notices published by the Ministry of Health are:<br/>
    - within AREA A:
    <ul key="A">
      <li key="A1">
      “Acqua, Clima e Salute: dalla protezione ambientale delle risorse, all’accesso all’acqua, alla sicurezza d’uso (ACeS)” – Leader Abruzzo Region
      </li>
      <li key="A2">
      “NecessARIA: necessità di strategie efficienti di ricambio dell'aria per la salute degli occupanti negli edifici scolastici” – Leader Autonomous Province of Bolzano
      </li>
      <li key="A3">
      “Aria outdoor e salute: un atlante integrato a supporto delle decisioni e della ricerca” – Leader Emilia Romagna Region
      </li>
      <li key="A4">
      “Valutazione dell’esposoma nei primi 1000 giorni in coorti di nati in aree ad elevata antropizzazione e attuazione di interventi per la riduzione del rischio” – Leader Autonomous Friuli Venezia Giulia Region
      </li>
      <li key="A5">
      “Cobenefici di salute ed equità a supporto dei piani di risposta ai cambiamenti climatici in Italia” – Leader Lazio Region
      </li>
      <li key="A6">
      “Monitoraggio abbattImento riSchi Sanitari InquinamentO iNdoor (MISSION)” – Leader Lombardia Region
      </li>
      <li key="A7">
      “Impatto dei contaminanti ambientali tossici e persistenti di interesse prioritario nei prodotti ittici del Mar Mediterraneo. Scenari di esposizione alimentare ed effetti sulla salute umana (CAP-fish)” – Leader Molise Region
      </li>
      <li key="A8">
      “Il sistema nazionale per il controllo e la sorveglianza dei chemicals a tutela della salute pubblica” – Leader Puglia Region
      </li>
      <li key="A9">
      “Valutazione della esposizione e della salute secondo l'approccio integrato One Health con il coinvolgimento delle comunità residenti in aree a forte pressione ambientale in Italia” – Leader Veneto Region
      </li>
    </ul>
    <br/>
    - within AREA B:
    <ul key="B">
      <li key="B1">
      “Il buon uso degli spazi verdi e blu per la promozione della salute e del benessere” – Leader Calabria Region
      </li>
      <li key="B2">
      “Biomonitoraggio di micro e nanoplastiche biodegradabili: dall'ambiente all'uomo in una prospettiva one health (BioPlast4SAFE)” – Leader Campania Region
      </li>
      <li key="B3">
      “ITWH: sistema gestionale per il benessere e la promozione del Total Worker Health nei luoghi di lavoro” – Leader Lombardia Region
      </li>
      <li key="B4">
      “Portale salute ambiente territoriale per la valutazione del rischio integrato” – Leader Marche Region
      </li>
      <li key="B5">
      “Sostenibilità per l'ambiente e la salute dei cittadini nelle città portuali in Italia” – Leader Puglia Region
      </li>
    </ul>
    <br/>
    <b>Financial allocation</b><br/>
    The total financial envelope available is 20.766.829,00 €.<br/><br/>
    <br/>Requests for technical assistance must be made exclusively through the <a href='https://cbim.freshdesk.com/support/home' target='_blank'  rel="noopener noreferrer">CBIM helpdesk</a> system.<br/>
    Before submitting the ticket, please consult the <a href='https://cbim.freshdesk.com/support/solutions'  target='_blank'  rel="noopener noreferrer">FAQ</a>.<br/><br/>
  </div>

const subImgTimer = <div>
  <Countdown lingua="EN" dataFine={1668898500000} dataFineEstesa={"Saturday 19 November at 23:55"} 
  messaggioCountdown={"The application submission phase will end in "}
  messaggioFine={"The application phase ended on"}
  messaggioDateNull={"The application phase will end "}
  afterCountdown={"."}
  />
  </div>

const subImg = <div style={{border:"1px solid black",backgroundColor:"#CCE5FF",padding:"5px"}}>
    It is possible to submit applications for the selection of two applied research projects "health-environment-biodiversity-climate" for the implementation of 
    interventions to be financed by investment E.1 "Health-environment-biodiversity-climate "Of the Complementary Plan to the National Recovery and Resilience Plan (PNC-E.1) ,
    the deadline is <b> November 19, 2022 at 23:55 </b>.
    <br/><br/>
    {subImgTimer}
    </div>

    return(
      <div className="pageContainer">
        <Helmet>
            <title>Prevention Workflow</title>
        </Helmet>
        <NavBar page="EN" sezione="0"/>
        <LinkButtonTop page="EN"/>
        <br/>
        <Presentazione
          titolo="Health, environment, biodiversity and climate (PNC)<br/>INVESTMENT E 1.4: promotion and financing of applied research with multidisciplinary approaches in specific areas of health-environment-climate intervention"
          testo={presentazioneContent}
          subImg={null}
        />
        <div>
            <Row style={{marginTop:"max(0.5vw,20px)"}} >
                <Col sm={6} xs={12}>
                    <AvvisiBando lingua="EN" position="L"/>
                </Col>
                <Col sm={6} xs={12}>
                    <AvvisiBando lingua="EN" position="R"/>
                </Col>
            </Row>
        </div>
        <JumbotronFooter/>
      </div>
    )
}