import React from 'react'
import {Row,Grid} from 'react-bootstrap'
import {Link} from 'gatsby'
import './LinkButtonTopStile.css'

export default function LinkButtonTop(props){

    return (
    <Row  style={{ marginLeft: "0", marginRight: "0",paddingLeft: "0", paddingRight: "0" }}>

        <div className="ColUno">
            <Link to={props.page==="IT" ?"/AccessoAlSistemaWorkflow":"/AccessWorkflowSystem"}>
                <button className="buttonLinkFirst">
                    {props.page==="IT" ? <>Accesso al Sistema Workflow</>:<>Access to the Workflow System</>}
                </button>
            </Link>
        </div>

        
        
        <div className="ColDueDoc">
            <Link to={props.page==="IT" ?"/Documentazione":"/Documentation"}>
                <button className="buttonLinkDoc">
                    {props.page==="IT" ? <div className="DocText">Documentazione</div>:<div className="DocText">Documentation</div>}
                </button>
            </Link>
        </div>
        
        
        
        <div className="ColTre">
        <a href="https://cbim.freshdesk.com/support/home" target="_blank" rel="noopener noreferrer">
            <button className="buttonLink">
                {props.page==="IT" ? <>Supporto Tecnico</>:<>Technical Support</>}
            </button>    
        </a>
        </div>
    </Row>
    )
}