import React,{useState,useEffect} from 'react'
import parser from 'react-html-parser';
export default function Countdown(props){

    const [timerProva,setTimerProva]=useState(0);

    const differenzaDate = (data) =>{
        //inizializzazione
        var ora = new Date().getTime();
        var richiesta = new Date(data).getTime();
        //converto in  secondi
        ora= (ora/1000).toFixed(0);
        richiesta = (richiesta/1000).toFixed(0);
        //faccio la differenza
        var differenza = richiesta - ora;
        //calcolo secondi
        var secondi_rimanenti = differenza % 60;
        differenza = (differenza - secondi_rimanenti)/60;
        //calcolo minuti
        var minuti_rimanenti = differenza % 60;
        differenza = (differenza - minuti_rimanenti)/60;
        //calcolo ore
        var ore_rimanenti = differenza % 24;
        //calcolo giorni
        var giorni = (differenza - ore_rimanenti)/24;
        return [ora,richiesta,giorni,ore_rimanenti,minuti_rimanenti]
    }

    const getDifference = (data,dataFineEstesa) =>{
        
        
        const [ora,richiesta,giorni,ore_rimanenti,minuti_rimanenti] = differenzaDate(data)
        var messaggio=new Date().toString()

        const giorniText = props.lingua === "IT" ? " Giorni " : " Days "
        const oreText = props.lingua === "IT" ? " Ore e " : " Hours and "
        const minutiText = props.lingua === "IT" ? " Minuti </b>"+props.afterCountdown+"<br/>" : " Minutes </b>"+props.afterCountdown+"<br/>"

        if(Number(ora) < Number(richiesta)){
            if(Number.isNaN(giorni)===true || Number.isNaN(ore_rimanenti)===true || Number.isNaN(minuti_rimanenti)===true  ){
                //Se valori NaN
                messaggio = props.messaggioDateNull + "<b>" + dataFineEstesa + "</b>."
            }else{
                //Se valori validi
                messaggio = props.messaggioCountdown + "<b>" + giorni+ giorniText +ore_rimanenti+ oreText + minuti_rimanenti + minutiText
            }
        }else{
            //Se data scadura
            messaggio =props.messaggioFine + "<b>"+ dataFineEstesa + "</b>.<br/>"
        }
    
        return parser(messaggio)
    }

    useEffect(()=>{
       const timer =  setInterval(()=>setTimerProva(getDifference(props.dataFine,props.dataFineEstesa)),1000);
       return () =>clearInterval(timer);
    },[timerProva])

    return  timerProva
}