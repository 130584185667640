import React from 'react'
import './stile.css'
import Avvisi from '../../Avvisi/Avvisi.json'
import parser from 'react-html-parser'
import Countdown from '../ExtraComponents/Countdown'
import TestoSemplice from '../ExtraComponents/TestoSemplice'
import { Row,Col } from 'react-bootstrap'
export default function AvvisiBando(props){

    const rosso = {backgroundColor:"#F8D7DA"}
    const verde = {backgroundColor:"#D4EDDA"}
    const azzurro = {backgroundColor:"#CCE5FF"} 
    const giallo = {backgroundColor:"#fff3cd"}

    const getSfondo = (ind) =>{
        if(ind===1) return rosso
        if(ind===2) return verde
        if(ind===3) return azzurro
        if(ind===4) return giallo
    }
    
    const getAvvisi = () =>{
        const body = []
        Avvisi.forEach(avviso=>{
            if(avviso.lingua === props.lingua && props.position === avviso.position && avviso.attivo !== false){
                var sfondo = getSfondo(avviso.coloreSfondo);
                const bodyAvviso = []
                avviso.components.forEach(componentAvviso=>{
                    if(componentAvviso.tipo === 0 && !componentAvviso.ignore) bodyAvviso.push(<TestoSemplice {...componentAvviso.config} key={componentAvviso.id} />)
                    if(componentAvviso.tipo === 1 && !componentAvviso.ignore) bodyAvviso.push(<Countdown {...componentAvviso.config} lingua={avviso.lingua} key={componentAvviso.id} />)
                })

                body.push(
                <div key={"container"+avviso.id} style={sfondo} className="bordoBando">
                    {avviso.titoloCustomPNRR && <Row noGutters className="WrapperPnnr">
                        <Col xs={12} sm={5} >
                            <div className='frame'>
                            <span className="helper"></span>
                                <img className='NextGenerationEUImg' src='https://s3.eu-south-1.amazonaws.com/documenti.cbim.it/NextGenerationEu.jpg' alt='NextGenerationEU' />
                            </div>
                        </Col>
                        <Col xs={12} sm={7} className={"titoloPnnr"} >
                            <br/><br/>
                        </Col>
                        </Row>}
                    <div className={"titoloBando"} >
                        {parser(avviso.titolo)}
                    </div>
                    <div className={"testoBando"} >
                        {bodyAvviso}
                    </div>
                </div>)
            }    
        })
        return <div>
            {body}
            <br/>
            </div>
    }

    return getAvvisi()
}