import React ,{Component}from 'react'
import './stile.css'
import Collegamenti from '../basicComponents/Collegamento'

export default class RaccoltaLinkENG extends Component{
    render(){
        return(
            <div className="RaccoltaCollegamenti">
                <strong>Links</strong>
                <div>
                    <Collegamenti indirizzo_url="http://www.salute.gov.it/"
                    nota="Ministry of Health"    
                    />
                    <Collegamenti indirizzo_url="https://moh-it.pure.elsevier.com/en/"
                    testo="Scientific IRCCS Network on Scival"    
                    />
                    <Collegamenti indirizzo_url="http://www.bibliosan.it/"
                    nota="Bibliosan"    
                    />
                    <Collegamenti indirizzo_url="http://ehealth.garr.it/"
                    nota="GARR E-Health"    
                    />
                    <Collegamenti indirizzo_url="https://moh-it.pure.elsevier.com/admin"
                    testo="Pure MoH"   
                    />
                    <Collegamenti indirizzo_url="https://s3.eu-south-1.amazonaws.com/documenti.cbim.it/20160620_Procedura%20Author%20Search%20e%20Researcher%20ID.pdf"
                    nota="Researcher ID Manual"    
                    />
                    <Collegamenti indirizzo_url="https://s3.eu-south-1.amazonaws.com/documenti.cbim.it/20160620_Instructions_withORCID_EN.pdf"
                    nota="ORCID ID manual"    
                    />
                    <Collegamenti indirizzo_url="https://researchpublications.scivalcontent.com/Account.aspx/LogOn?ReturnUrl=%2fHome.aspx"
                    testo="SciVal modules"
                    />
                    <Collegamenti indirizzo_url="https://s3.eu-south-1.amazonaws.com/documenti.cbim.it/IRCCS_IFN_2012-2020.pdf"
                    testo="Scientific Production IRCCS 2012-2020"    
                    />
                </div>
                    <Collegamenti indirizzo_url="https://s3.eu-south-1.amazonaws.com/documenti.cbim.it/privacy.pdf"
                    nota="Privacy Policy"    
                    />     
                </div>
        )//Return
    }//Render method
}//class Component